import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Video from '../components/video'

function Hero(props) {
  return (
    <section id="hero">
      <div className="hero_mobile">
        <StaticImage
          src="../images/hero_mobile.jpg"
          alt="doctor who hero graphic"
        />
      </div>
      <div className="hero_desktop">
        <Video src='/hero.mp4' />
        <StaticImage
          src="../images/hero_graphic.jpg"
          alt="doctor who hero graphic"
          className='hero-graphic-image'
        />
      </div>

      {/*<AnchorLink className="button__scroll" to="/#the-exhibition" title="THE EXHIBITION">
        <button>
          SCROLL DOWN
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 13.5L16.5 18.5L21.5 13.5H11.5Z" fill="#F7BB6B" />
          </svg>
        </button>
      </AnchorLink>*/}

    </section>
  )
}

export default Hero
