import * as React from 'react'
import { Navigation, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css'

function Testimonials(props) {
  return (
    <Swiper
      modules={[Navigation, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
    >
      <SwiperSlide>
        <div className="quote">
          <blockquote>
            “For generations the many writers of Doctor Who have written stories inspired by real science - astronomy, astrobiology,
            quantum physics and nature itself… The exhibition is a celebration of the show but also the amazing breadth of scientific ideas behind it”.
          </blockquote>
          <span className="quote__name">- Fiona Philpott,	&nbsp;</span><span>Director of Exhibitions at National Museums Liverpool</span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="quote">
          <blockquote>
            “Exploring some of the fantastic stories and characters from a scientific perspective will bring with it an exciting new way for our audiences to engage with Doctor Who.  Props, costumes and other artefacts from the show will help create truly fascinating Worlds of Wonder.”{' '}
          </blockquote>
          <span className="quote__name">- Jeff Parker,	&nbsp;</span><span>Creative Producer BBC Studios Live Entertainment.</span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="quote">
          <blockquote>
            “So many people who have gone on to work in science have had their interest piqued by watching Doctor Who, and one of the amazing things about the show is its ability to make us wonder. I’m thrilled to be part of the exhibition and I do hope as many curiously minded people as possible take the opportunity to visit.”{' '}
          </blockquote>
          <span className="quote__name">- Mark Gatiss,	&nbsp;</span><span>Actor and writer.</span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="quote">
          <blockquote>
            “Cleverly designed so that as you walk around, you almost feel you could be in the TARDIS, or perhaps somehow trapped within an episode of the TV series… If the exhibition’s aim is to inspire people to learn more about science, then it worked on me.”{' '}
          </blockquote>
          <span className="quote__name">- New Scientist Magazine</span>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="quote">
          <blockquote>
            “Just wanted to say how wonderful the Doctor Who exhibit is. Took my daughter and four young grand-daughters today and they spent over two hours in the exhibit. They are aged 4–9 and have only seen a few selected episodes but they enjoyed seeing all the costumes, the interactive elements, how Doctor Who relates to science and nature etc. I wasn't expecting a lot, a few costumes etc but was very pleasantly surprised by the whole exhibit from start to finish. Absolutely fabulous.”{' '}
          </blockquote>
          <span className="quote__name">- Anonymous visitor feedback</span>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default Testimonials
