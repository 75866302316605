import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Navigation, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css'

function Quote(props) {
  return (
    <Swiper
      modules={[Navigation, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
    >
      <SwiperSlide>
        <StaticImage
          src="../images/z1-1.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="Vortex Corridor. Begin your journey through the TARDIS doors and pass along walls lined with words of wisdom from real scientists... and the Doctor."
        />
        <span>
          <b>Vortex Corridor.</b> Begin your journey through the TARDIS doors and pass along walls lined with words of wisdom from real scientists... and the Doctor.
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/z2-1.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="In the TARDIS Tech Room visitors discover more about the Doctor's gadgets, time travel, cloaking technology, and why the Doctor's famous blue box is bigger on the inside."
        />
        <span>
          <b>In the TARDIS Tech Room</b> visitors discover more about the Doctor's gadgets, time travel, cloaking technology, and why the Doctor's famous blue box is bigger on the inside.
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/z2-2.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="In the TARDIS Tech Room visitors discover more about the Doctor's gadgets, time travel, cloaking technology, and why the Doctor's famous blue box is bigger on the inside."
        />
        <span>
          <b>In the TARDIS Tech Room</b> visitors discover more about the Doctor's gadgets, time travel, cloaking technology, and why the Doctor's famous blue box is bigger on the inside.
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/z4-1.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="Cosmic Curiosities looks to the stars: here visitors learn more about deep space exploration, black holes, and the latest thinking on big questions challenging astroscientists today."
        />
        <span>
          <b>Cosmic Curiosities</b> looks to the stars: here visitors learn more about deep space exploration, black holes, and the latest thinking on big questions challenging astroscientists today.
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/z5-1.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="Bioscience Frontiers explores life on Earth: how weird and wonderful nature has inspired ideas seen in Doctor Who, and how the natural world is often stranger than fiction."
        />
        <span>
          <b>Bioscience Frontiers explores life on Earth:</b> how weird and wonderful nature has inspired ideas seen in Doctor Who, and how the natural world is often stranger than fiction.
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/z5-2.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="Bioscience Frontiers explores life on Earth: how weird and wonderful nature has inspired ideas seen in Doctor Who, and how the natural world is often stranger than fiction."
        />
        <span>
          <b>Bioscience Frontiers explores life on Earth:</b> how weird and wonderful nature has inspired ideas seen in Doctor Who, and how the natural world is often stranger than fiction.
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/z6-1.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="The Monster Vault. Enter if you dare! Awaiting you is a rogue’s gallery featuring some of the show’s scariest, most memorable monsters."
        />
        <span>
          <b>The Monster Vault.</b> Enter if you dare! Awaiting you is a rogue’s gallery featuring some of the show’s scariest, most memorable monsters.
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/z6-2.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="The Monster Vault. Enter if you dare! Awaiting you is a rogue’s gallery featuring some of the show’s scariest, most memorable monsters."
        />
        <span>
          <b>The Monster Vault.</b> Enter if you dare! Awaiting you is a rogue’s gallery featuring some of the show’s scariest, most memorable monsters.
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/z7-1.jpg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="The Lab looks at how science is revolutionising our world, and as technology races ahead, what might it mean for the future of our species - and our planet?"
        />
        <span>
          <b>The Lab</b> looks at how science is revolutionising our world, and as technology races ahead, what might it mean for the future of our species - and our planet?
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/gallery-1.jpeg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="Mark Gatiss, Narrator"
        />
        <span>
          <b>Mark Gatiss</b>, Narrator
        </span>
      </SwiperSlide>
      <SwiperSlide>
        <StaticImage
          src="../images/gallery-2.jpeg"
          placeholder="blurred"
          quality={95}
          objectFit="contain"
          formats={['auto', 'webp', 'avif']}
          alt="Exhibition merchandise"
        />
        <span>
          <b>Exhibition merchandise</b>
        </span>
      </SwiperSlide>
    </Swiper>
  )
}

export default Quote
