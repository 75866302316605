import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import { Link } from "gatsby"

function Features(props) {
  return (
    <div className="the-exhibition-features">
      <div className="the-exhibition-features__feature">
        <StaticImage
          src="../images/wealth-of-scientific-topics.png"
          width={220}
          placeholder="blurred"
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt="DNA helix"
        />
        <h3>A WEALTH OF SCIENTIFIC TOPICS</h3>
      </div>
      <div className="the-exhibition-features__feature">
        <StaticImage
          src="../images/iconic-monsters-gadgets-props.png"
          width={220}
          placeholder="blurred"
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt="A Dalek - The Doctor's iconic arch-enemy"
        />
        <h3>ICONIC MONSTERS, GADGETS AND PROPS</h3>
      </div>
      <div className="the-exhibition-features__feature">
        <StaticImage
          src="../images/rare-models-sketches-concept-artworks.png"
          width={220}
          placeholder="blurred"
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt="K9: the Doctor's robot dog"
        />
        <h3>UNIQUE MODELS, SKETCHES AND CONCEPT ARTWORKS</h3>
      </div>
      <div className="the-exhibition-features__feature">
        <StaticImage
          src="../images/contributions-from-experts-who-bring-show-to-life.png"
          width={250}
          placeholder="blurred"
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt="Machine cogs"
        />
        <h3>
          CONTRIBUTIONS FROM SPECIALISTS BRING THE SHOW TO LIFE
        </h3>
      </div>
    </div>
  )
}

export default Features
