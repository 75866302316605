import * as React from 'react'
// import { Link } from "gatsby"

function Quote(props) {
  return (
    <div className="quote">
      <blockquote>
        “Outside those doors, we might see anything. We could find new worlds,
        terrifying monsters, impossible things. And if you come with me… nothing
        will ever be the same again!”{' '}
      </blockquote>
      <span className="quote__name">- The Tenth Doctor</span>
    </div>
  )
}

export default Quote
