import * as React from 'react'

function Cta(props) {
  return (
    <div className="cta">
      <h3>Would you like to host</h3>
      <h2>Doctor Who Worlds of Wonder</h2>
      <p>For Further Venue Information Packs</p>
      <a href="mailto:dwt@sarner.com"><button>EMAIL US</button></a>
      {/* &nbsp; or &nbsp;<button>CALL US</button> */}
    </div>
  )
}

export default Cta
